import { InteractionRequiredAuthError } from "@azure/msal-browser";
import type { RuntimeConfig } from "nuxt/schema";
import { useCurrentPlant } from "~/store/plant-state.ts";
import { useGlobalNotification } from "~/store/global-notification.ts";
import { SharedCacheHandler } from "~/utils/sharedCacheHandler.ts";
import * as Sentry from "@sentry/vue";

export type CreateCustomFetchOpts = {
  beforeRequest?: (request: Request) => Promise<void>;
  handleResponse?: (request: Request, response: Response) => Promise<void>;
  cache?: SharedCacheHandler;
};

export function getApiScopes(config: RuntimeConfig): string[] {
  return [
    config.public.AZURE_AD_SCOPES === ""
      ? `api://${config.public.AZURE_AD_CLIENT_ID}/ListPlants`
      : config.public.AZURE_AD_SCOPES,
  ];
}

export type CustomFetchHandler = {
  (input: RequestInfo | URL, init?: RequestInit): Promise<Response>;
  meta: {
    request: Request;
    response: Response;
  };
};

/**
 *
 * @param opts - Options for various stages of the fetch handling
 */
export function createCustomFetch(opts?: CreateCustomFetchOpts): CustomFetchHandler {
  const globalNotification = useGlobalNotification();
  const { $auth, $i18n } = useNuxtApp();
  const { data: currentPlant } = storeToRefs(useCurrentPlant());

  const CustomFetchOpts: CreateCustomFetchOpts = Object.assign(
    {
      async beforeRequest() {},
      async handleResponse(req: Request, res: Response) {
        const acceptHeader = `${req.headers.get("accept")}`.split(", ");
        const contentType = `${res.headers.get("content-type")}`.split(";")[0];
        if (res.status >= 500) {
          globalNotification.showError("errors.generic");
        } else if (!req.headers.has("accept") || !acceptHeader.includes(contentType)) {
          devConsole.error("accept and content-type mismatch", acceptHeader, contentType);
          devConsole.trace("accept and content-type mismatch");
          globalNotification.showDevError("errors.badContentType");
        }
      },
    },
    opts || {},
  );

  const meta = {
    request: new Request("null://"),
    response: new Response(),
  };

  const customFetch = async (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
    const request = new Request(input, init);
    const headers = request.headers;
    if (typeof currentPlant.value?.plantId === "number") {
      headers.append("Nexcor-Plant", `${currentPlant.value.plantId}`);
    }
    try {
      // stw - Should add accept header when one is not provided
      // (most APIs will be this unless returning PDF or other blob)
      if (!headers.has("Accept")) {
        headers.append("Accept", "application/json");
      }

      headers.append("Accept-Language", $i18n.locale.value);
      const token = await $auth.getToken();
      if (!token) {
        globalNotification.showError("Expected a Authorization token, found none.");
      } else {
        const bearer = "Bearer " + token;
        headers.append("Authorization", bearer);
      }
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        await $auth.refreshToken();
      } else if (e instanceof Error) {
        globalNotification.showError(e.toString());
      }
    }

    await CustomFetchOpts.beforeRequest!(request);
    let response;
    if (CustomFetchOpts.cache instanceof SharedCacheHandler && request.method === "GET") {
      response = await CustomFetchOpts.cache.call(request, () => fetch(request));
    } else {
      // Wrapping actual fetches to report to Sentry
      response = await Sentry.startSpan({ name: `${request.method} ${request.url}`, op: "http.client" }, async () => {
        return await fetch(request);
      });
    }
    meta.request = request;
    meta.response = response.clone();
    await CustomFetchOpts.handleResponse!(request, response.clone());
    return response.clone();
  };

  customFetch.meta = meta;

  // Wrap every fetch with a span to send info to sentry
  return customFetch;
}

export const customFetchAcceptHeaderOpts: (mimeType: string) => CreateCustomFetchOpts = (mimeType: string) => ({
  async beforeRequest(request) {
    request.headers.append("accept", mimeType);
  },
});
