export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("doubleClick", {
    created(el, binding) {
      const { doubleClick } = useDoubleClick();

      const dblClickCallback = binding.value;

      el.addEventListener("click", () => doubleClick(dblClickCallback));
    },
    unmounted(el, binding) {
      const { doubleClick } = useDoubleClick();

      const dblClickCallback = binding.value;

      el.removeEventListener("click", () => doubleClick(dblClickCallback));
    },
  });
});
