<script setup lang="ts">
import { useEquipmentLookupListStore } from "~/store/equipment-areas.ts";
import { useCurrentPlant } from "~/store/plant-state.ts";
import { useProvideLangDir } from "~/composables/provided-state.ts";
import { useGlobalNotification } from "~/store/global-notification.ts";

const head = useLocaleHead({ addDirAttribute: true, addSeoAttributes: true });

const directionRef = computed<"rtl" | "ltr">(() => head.value.htmlAttrs?.dir ?? "ltr");

useProvideLangDir(directionRef);

const globalNotification = useGlobalNotification();

function handleClose() {
  globalNotification.clearMessage();
}

const { $auth } = useNuxtApp();
const PlantData = useCurrentPlant();
const isAuthed = await $auth.isAuthed();
if (isAuthed && PlantData.data?.plantId) {
  const equipmentLookupListStore = useEquipmentLookupListStore();
  equipmentLookupListStore.fetch();
}
</script>
<template>
  <Html v-bind="head.htmlAttrs" />
  <div>
    <LoadingGlobalPage />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <GlobalNotificationBar :notification="globalNotification" @close="handleClose" />
  </div>
</template>
<style scoped lang="postcss">
/**
intentionally left blank
 */
</style>
<style lang="postcss">
* {
  direction: v-bind("directionRef");
}
</style>
