import { useStorage, StorageSerializers } from "@vueuse/core";
import { useCompanyInfoStore } from "~/store/company-info";
import { useConnectStatusStore } from "~/store/connect-status";
import { useEquipmentLookupListStore } from "~/store/equipment-areas.ts";
import { useGlobalNotification } from "~/store/global-notification.ts";

export type PlantData = {
  plantId?: number;
  plantName?: string;
  plantNumber?: string;
  licenseId?: number;
  licenseName?: string;
  acc?: string;
};

/**
 * This will return handlers for specifying the current plant. This value is used by our api handlers to set the
 * `Nexcor-Plant` header.
 */
export const useCurrentPlant = defineStore("currentPlant", () => {
  const currentPlant = useStorage<PlantData | null>("KLEANZ_PLANT", null, localStorage, {
    serializer: StorageSerializers.object,
  });

  const setPlant = (plant: PlantData | null) => {
    if (plant !== null) {
      currentPlant.value = plant;

      const globalNotification = useGlobalNotification();
      globalNotification.showSuccess("home.welcomeBack");
    }
    return currentPlant.value;
  };

  const clearPlant = () => {
    currentPlant.value = null;
  };

  // Ensure no one changes the value of the current plant directly.
  // Use an empty object as a fallback because `readonly` gets angry at null.
  const data = computed<PlantData>(() => readonly(currentPlant.value || {}));

  return {
    data,
    setPlant,
    clearPlant,
  };
});

export const useResetPlantData = () => {
  const companyInfoStore = useCompanyInfoStore();
  const connectStatusStore = useConnectStatusStore();
  const currentPlant = useCurrentPlant();
  const EquipmentLookupListStore = useEquipmentLookupListStore();
  const reset = () => {
    companyInfoStore.reset();
    connectStatusStore.reset();
    currentPlant.clearPlant();
    EquipmentLookupListStore.voidCache();
    EquipmentLookupListStore.reset();
  };
  return {
    reset,
  };
};
