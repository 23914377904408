import { ref } from "vue";

export type MenuNames =
  | "main"
  | "task-scheduling"
  | "create-worksheets"
  | "corrective-actions"
  | "workpacks"
  | "settings"
  | "reporting";

export const usePiniaCurrentMenu = defineStore("currentMenu", () => {
  const route = useRoute();
  const currentMenu = ref<MenuNames>(route.meta.menu || "main");
  const update = (val: MenuNames) => {
    currentMenu.value = val;
  };
  const value = readonly(currentMenu);
  return { update, value };
});
