declare module "#app" {
  interface PageMeta {
    menu?: MenuNames;
  }
}

declare module "vue-router" {
  interface RouteMeta {
    menu?: MenuNames;
  }
}

import { usePiniaCurrentMenu, type MenuNames } from "~/store/current-menu";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("page:finish", () => {
    const currentMenu = usePiniaCurrentMenu();
    const route = useRoute();
    const nextMenu = route.meta.menu ? route.meta.menu : "main";
    currentMenu.update(nextMenu);
  });
});
