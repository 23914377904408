type GetCacheStorageValue = {
  response: Promise<Response>;
  expires: number;
  cancelTimeout: NodeJS.Timeout;
};
type GetCacheStorage = Map<string, GetCacheStorageValue>;

export class SharedCacheHandler {
  static #sharedCache: Map<string, GetCacheStorage> = new Map();
  constructor(
    public cacheName: string,
    public timeout: number,
  ) {
    if (!SharedCacheHandler.#sharedCache.has(cacheName)) {
      SharedCacheHandler.#sharedCache.set(cacheName, new Map());
    }
  }
  #getKey(request: Request): string {
    const plantId = request.headers.get("Nexcor-Plant") ?? "";
    const url = request.url;
    return `plant:${plantId},url:${url}`;
  }
  get #cache() {
    return SharedCacheHandler.#sharedCache.get(this.cacheName)!;
  }
  delete(request: Request) {
    const cachedValue = this.#cache.get(this.#getKey(request));
    if (!cachedValue) {
      return;
    }
    clearTimeout(cachedValue.cancelTimeout);
    this.#cache.delete(this.#getKey(request));
  }
  has(request: Request): boolean {
    if (!this.#cache.has(this.#getKey(request))) return false;
    const cachedValue: GetCacheStorageValue = this.#cache.get(this.#getKey(request)) as GetCacheStorageValue;
    if (cachedValue.expires < Date.now()) {
      this.delete(request);
      return false;
    }
    return true;
  }
  get(request: Request): Promise<Response> | undefined {
    if (!this.has(request)) return;
    const key = this.#getKey(request);
    const cachedStuff = this.#cache.get(key)!;
    return cachedStuff.response;
  }
  set(request: Request, callback: () => Promise<Response>): Promise<Response> {
    if (this.has(request)) {
      this.delete(request);
    }
    const key = this.#getKey(request);
    const response = callback();
    this.#cache.set(key, {
      response,
      expires: Date.now() + this.timeout,
      cancelTimeout: setTimeout(() => {
        if (this.#cache.has(key)) {
          devConsole.info(`Deleting from cache "${this.cacheName}": ${key}`);
          this.#cache.delete(key);
        }
      }, this.timeout),
    });
    return response;
  }
  async call(request: Request, callback: () => Promise<Response>): Promise<Response> {
    if (request.method !== "GET") return callback();
    let cachedResponse = this.get(request);
    if (!cachedResponse) cachedResponse = this.set(request, callback);
    return (await cachedResponse).clone();
  }
}

declare global {
  interface Window {
    SharedCacheHandler: typeof SharedCacheHandler;
  }
}

if (import.meta.env.MODE === "development") {
  window.SharedCacheHandler = SharedCacheHandler;
}
