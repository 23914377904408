export const useDoubleClick = () => {
  const count = ref(0);
  const timer = ref(null);
  const cb = ref(null);

  const doubleClick = (callback = cb.value, timeout = 300) => {
    cb.value = callback;
    count.value++;
    if (count.value < 2) {
      timer.value = setTimeout(() => {
        count.value = 0;
      }, timeout);
    } else if (count.value > 1) {
      clearTimeout(timer.value);
      count.value = 0;
      if (typeof cb.value === "function") cb.value();
    }
  };

  return {
    doubleClick,
  };
};

export const useMouseX = () => useState("mouseX", () => 0);
export const useMouseY = () => useState("mouseY", () => 0);

export const useMouseCoords = () => {
  const mouseX = useMouseX();
  const mouseY = useMouseY();

  const updateCoords = (e) => {
    mouseX.value = e?.clientX || e?.pageX || mouseX.value;
    mouseY.value = e?.clientY || e?.pageY || mouseY.value;
  };

  return {
    mouseX,
    mouseY,
    updateCoords,
  };
};
