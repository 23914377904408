/**
 * PlantApi
 * 1.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.Defaults<Oazapfts.CustomHeaders> = {
    headers: {},
    baseUrl: "/",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type CorpCatDto = {
    "number": number;
    name: string;
    active: boolean;
};
export type DataStatus = "success" | "notValid" | "exception" | "error" | "inUse" | "notFound" | "noChange" | "inProgress" | "invalidPage" | "duplicateEntity";
export type ErrorResponse = {
    message: string;
    kind: DataStatus;
    exception: string | null;
};
export type EquipDto = {
    "number": number;
    name: string;
    model: string;
    serialNumber: string;
    dateManufactured?: string | null;
    dateInstalled?: string | null;
    comments: string;
    assetNumber: string;
    startTaskCalculationDate: string;
    downDay: string;
    datePurchased?: string | null;
    lastDate?: string | null;
    isAsset: boolean;
    nextMaterialNumber: number;
    active: boolean;
    startHours: number;
    lineNumber: number;
    dayNumber: number;
    profitCenter?: number | null;
    manufactureVendorNumber: number;
    location: string;
    reminderToRestartDate?: string | null;
    nextNumberToStoreDeletedTask: number;
    workGroupNumber: number;
    legacyEquipmentNumber: string;
    critical: boolean;
    hasNoTasks: boolean;
    corpEquipNum: string;
    includeInInspections: boolean;
    lastHours: number;
    workpackDayNumber: number;
    eqVerNum: number;
    eqCreatedByUser: number;
    eqCreatedDate?: string | null;
    eqLastUser: number;
    eqLastModifiedDate?: string | null;
    taskVersionNumber: number;
    taskCreatedByUser: number;
    taskCreatedDate?: string | null;
    taskLastUser: number;
    taskLastModifiedDate?: string | null;
    taskPdfName: string;
    manualPdfName: string;
    includeInWo: boolean;
    corporateCategoryNumber: number;
    purchasePrice?: number | null;
    taskCheckOutUser: number;
    taskCheckOutDate?: string | null;
    taskHeight: number;
    mobileGenerateSheet: boolean;
    noTaskForward: boolean;
    corporateReportUse?: number | null;
    corporateLock: boolean;
    isNoDelete: boolean;
};
export type EquipmentHistoryViewDto = {
    historyDate: string;
    "type": string;
    id: number;
    employee: string;
    status: string;
    hours: number;
    comment: string;
};
export type PdfNameDto = {
    "number": number;
    success: boolean;
};
export type DefaultAssignmentDto = {
    equipmentNumber: number;
    employeeNumber: number;
    skillTypeCode: string;
};
export type EmployeeSkillDto = {
    employee: number;
    skillCode: string;
};
export type EquipLookupView = {
    "number": number;
    name: string;
    isActive: boolean;
    allergenLevel: number;
};
export type EquipFilter = {
    isAnyFilter: boolean;
    equipName: string;
    groups: number[];
    corpCategories: number[];
    lines: number[];
    includeActive: boolean;
    includeInactive: boolean;
    includeCritical: boolean;
    includeNonCritical: boolean;
    location: string;
    model: string;
    sortAscending: boolean;
    sort: string;
};
export type EquipAreaViewDto = {
    "number": number;
    name: string;
    isAsset: boolean;
    active: boolean;
    lineNumber: number;
    lineName: string;
    dayNumber: number;
    location: string;
    workGroupNumber: number;
    workGroupName: string;
    changeoverLevel: number;
    critical: boolean;
    hasNoTasks: boolean;
    corpEquipNum: string;
    includeInInspections: boolean;
    workpackDayNumber: number;
    includeInWo: boolean;
    corporateCategoryNumber: number;
    corporateCategoryName: string;
    taskHeight: number;
    mobileGenerateSheet: boolean;
    noTaskForward: boolean;
    corporateReportUse: number;
    corporateLock: boolean;
    isNoDelete: boolean;
};
export type EquipAreaViewDtoListResultSet = {
    totalResults: number;
    totalPages: number;
    currentPage: number;
    pageSize: number;
    previousUrl: string | null;
    nextUrl: string | null;
    filter: any | null;
    data: EquipAreaViewDto[];
};
export type EquipFilterListResultSet = {
    totalResults: number;
    totalPages: number;
    currentPage: number;
    pageSize: number;
    previousUrl: string | null;
    nextUrl: string | null;
    filter: any | null;
    data: EquipFilter[];
};
export type EquipLookupSimpleView = {
    "number": number;
    name: string;
};
export type LineDto = {
    "number": number;
    name: string;
    location: string;
    dateInstalled?: string | null;
    active: boolean;
    comments: string;
    gmp: boolean;
};
export type VendorDto = {
    "number": number;
    name: string;
    address1: string;
    address2: string;
    address3: string;
    phone1: string;
    phone2: string;
    fax: string;
    faxAttn: string;
    website: string;
    contact1: string;
    title1: string;
    email1: string;
    contact2: string;
    title2: string;
    email2: string;
    note1: string;
    keywords: string;
    corp: string;
    active: boolean;
    currency: string;
};
export type VendorDtoListResultSet = {
    totalResults: number;
    totalPages: number;
    currentPage: number;
    pageSize: number;
    previousUrl: string | null;
    nextUrl: string | null;
    filter: any | null;
    data: VendorDto[];
};
export type VendorLookupView = {
    "number": number;
    name: string;
};
export type WorkGroupDto = {
    "number": number;
    name: string;
    active: boolean;
    comment: string;
    changeoverLevel: number;
    defaultAllSelection: boolean;
};
export function getCorporateCategoryList(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: CorpCatDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>("/api/corpcategory", {
        ...opts
    });
}
export function createCorporateCategory(corpCatDto: CorpCatDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: CorpCatDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 409;
    } | {
        status: 500;
    }>("/api/corpcategory", oazapfts.json({
        ...opts,
        method: "POST",
        body: corpCatDto
    }));
}
export function getCorporateCategory($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: CorpCatDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
    } | {
        status: 500;
    }>(`/api/corpcategory/${encodeURIComponent($number)}`, {
        ...opts
    });
}
export function updateCorporateCategory($number: number, corpCatDto: CorpCatDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: CorpCatDto;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>(`/api/corpcategory/${encodeURIComponent($number)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: corpCatDto
    }));
}
export function deleteCorporateCategory($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 409;
    } | {
        status: 500;
    }>(`/api/corpcategory/${encodeURIComponent($number)}`, {
        ...opts,
        method: "DELETE"
    });
}
export function getEquipment($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EquipDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
    } | {
        status: 500;
    }>(`/api/equipment/${encodeURIComponent($number)}`, {
        ...opts
    });
}
export function updateEquipment($number: number, equipDto: EquipDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EquipDto;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>(`/api/equipment/${encodeURIComponent($number)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: equipDto
    }));
}
export function deleteEquipment($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 409;
    } | {
        status: 500;
    }>(`/api/equipment/${encodeURIComponent($number)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Equipment/Area Number is provided by the End User. It is not auto generated. 409 error on attempted duplicate.
 */
export function createEquipment(equipDto: EquipDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EquipDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 409;
    } | {
        status: 500;
    }>("/api/equipment", oazapfts.json({
        ...opts,
        method: "POST",
        body: equipDto
    }));
}
/**
 * Sets the Worksheet Tasks for the Equipment/Area due
 */
export function setEquipmentForceDue($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>(`/api/equipment/${encodeURIComponent($number)}/forcedue`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Gets 6 months of History for the Equipment/Area History Tab's Table in descending date order
 */
export function getEquipmentHistory($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EquipmentHistoryViewDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>(`/api/equipment/${encodeURIComponent($number)}/history`, {
        ...opts
    });
}
/**
 * Get PDF in Stream
 */
export function downloadEquipmentManual($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: string;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>(`/api/equipment/${encodeURIComponent($number)}/manual/pdf`, {
        ...opts
    });
}
/**
 * Upload PDF to Equipment/Area, New or Replacement PDF. Only one per Equipment/Area-Manual.   multipart/form-data
 */
export function uploadEquipmentManual($number: number, body?: {
    files: Blob[];
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: PdfNameDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>(`/api/equipment/${encodeURIComponent($number)}/manual/setpdf`, oazapfts.multipart({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Get PDF in Stream
 */
export function downloadEquipmentWorksheet($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: string;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>(`/api/equipment/${encodeURIComponent($number)}/worksheet/pdf`, {
        ...opts
    });
}
/**
 * Upload PDF to Equipment/Area, New or Replacement PDF. Only one per Equipment/Area-Worksheet.   multipart/form-data
 */
export function uploadWorksheet($number: number, body?: {
    files: Blob[];
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: PdfNameDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>(`/api/equipment/${encodeURIComponent($number)}/worksheet/setpdf`, oazapfts.multipart({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Get Default Assignments for Equipment/Area
 */
export function getEquipmentDefaultAssignmentList($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DefaultAssignmentDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>(`/api/equipment/${encodeURIComponent($number)}/defaultassignments`, {
        ...opts
    });
}
/**
 * Create/Update/Remove Default Assignments for Equipment/Area
 */
export function createEquipmentDefaultAssignment($number: number, body: EmployeeSkillDto[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DefaultAssignmentDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>(`/api/equipment/${encodeURIComponent($number)}/defaultassignments`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Get Specific Default Assignment (if exists) for Equipment Area - Equipment/Area Number and Skill Type Code
 */
export function getEquipmentDefaultAssignment($number: number, code: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DefaultAssignmentDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
    } | {
        status: 500;
    }>(`/api/equipment/${encodeURIComponent($number)}/skill/${encodeURIComponent(code)}`, {
        ...opts
    });
}
/**
 * Delete Default Assignment for Equipment Area and Skill Type Code
 */
export function deleteEquipmentDetaulAssignment($number: number, code: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 409;
    } | {
        status: 500;
    }>(`/api/equipment/${encodeURIComponent($number)}/skill/${encodeURIComponent(code)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Number and Name for Equipment/Area for use in most Drop Downs
 */
export function getEquipmentLookupList(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EquipLookupView[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>("/api/equipmentlookuplist", {
        ...opts
    });
}
/**
 * A filter for a specific type of equipment for use in finding items in the equipment list.
 */
export function getEquipmentFilterList(equipFilter: EquipFilter, { page, pageSize }: {
    page?: number;
    pageSize?: number;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EquipAreaViewDtoListResultSet;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>(`/api/equipmentfilter${QS.query(QS.explode({
        page,
        pageSize
    }))}`, oazapfts.json({
        ...opts,
        method: "POST",
        body: equipFilter
    }));
}
/**
 * A default filter as defined for a specific client.
 */
export function getEquipmentDefaultFilter(name: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EquipFilterListResultSet;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
    } | {
        status: 500;
    }>(`/api/equipmentfilter/default/${encodeURIComponent(name)}`, {
        ...opts
    });
}
/**
 * Number and Name for Equipment/Area for use in Inspection Detail Drop Downs. There is another endpoint for most Equip/Area lookups.
 */
export function getEquipmentLookupListForInspection(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EquipLookupSimpleView[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>("/api/equipmentlookuplistforinspection", {
        ...opts
    });
}
export function getEquipmentLineList(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LineDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>("/api/line", {
        ...opts
    });
}
/**
 * Production Line Number is provided by the End User. It is not auto generated. 409 error on attempted duplicate.
 */
export function createEquipmentLine(lineDto: LineDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LineDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 409;
    } | {
        status: 500;
    }>("/api/line", oazapfts.json({
        ...opts,
        method: "POST",
        body: lineDto
    }));
}
export function getEquipmentLine($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LineDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
    } | {
        status: 500;
    }>(`/api/line/${encodeURIComponent($number)}`, {
        ...opts
    });
}
export function updateEquipmentLine($number: number, lineDto: LineDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LineDto;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>(`/api/line/${encodeURIComponent($number)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: lineDto
    }));
}
export function deleteEquipmentLine($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 409;
    } | {
        status: 500;
    }>(`/api/line/${encodeURIComponent($number)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * ** Required Pagination Parameters **
 */
export function getVendorList({ page, pageSize, name }: {
    page?: number;
    pageSize?: number;
    name?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: VendorDtoListResultSet;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>(`/api/vendor${QS.query(QS.explode({
        page,
        pageSize,
        name
    }))}`, {
        ...opts
    });
}
export function createVendor(vendorDto: VendorDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: VendorDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 409;
    } | {
        status: 500;
    }>("/api/vendor", oazapfts.json({
        ...opts,
        method: "POST",
        body: vendorDto
    }));
}
export function getVendor($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: VendorDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
    } | {
        status: 500;
    }>(`/api/vendor/${encodeURIComponent($number)}`, {
        ...opts
    });
}
export function updateVendor($number: number, vendorDto: VendorDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: VendorDto;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>(`/api/vendor/${encodeURIComponent($number)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: vendorDto
    }));
}
export function deleteVendor($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 409;
    } | {
        status: 500;
    }>(`/api/vendor/${encodeURIComponent($number)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Number and Name for Active Vendors for use in Drop Downs.
 */
export function getVendorLookupList(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: VendorLookupView[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>("/api/vendorlookuplist", {
        ...opts
    });
}
export function getWorkGroupList(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: WorkGroupDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>("/api/workgroup", {
        ...opts
    });
}
/**
 * Work Group Number is provided by the End User. It is not auto generated. 409 error on attempted duplicate.
 */
export function createWorkGroup(workGroupDto: WorkGroupDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: WorkGroupDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 409;
    } | {
        status: 500;
    }>("/api/workgroup", oazapfts.json({
        ...opts,
        method: "POST",
        body: workGroupDto
    }));
}
export function getWorkGroup($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: WorkGroupDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
    } | {
        status: 500;
    }>(`/api/workgroup/${encodeURIComponent($number)}`, {
        ...opts
    });
}
export function updateWorkGroup($number: number, workGroupDto: WorkGroupDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: WorkGroupDto;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>(`/api/workgroup/${encodeURIComponent($number)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: workGroupDto
    }));
}
export function deleteWorkGroup($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 409;
    } | {
        status: 500;
    }>(`/api/workgroup/${encodeURIComponent($number)}`, {
        ...opts,
        method: "DELETE"
    });
}
