import { useLocalFetch } from "~/composables/apiHandler.ts";
import { getEquipmentLookupList } from "~/connectables/fetch/equipmentAreaManagement.ts";
import { getEquipmentLookupListForInspection } from "~/connectables/fetch/equipmentAreaManagement.ts";
import { defineStore } from "pinia";
import { TimeInMS } from "~/utils/dateTimeHelpers.ts";
import { usePresortedLists } from "~/composables/presortedLists.ts";

export const useEquipmentLookupListStore = defineStore("EquipmentLookupList", () => {
  const { fetch: originalFetch, promise, ...theRest } = useLocalFetch("getEquipmentLookupList", getEquipmentLookupList);
  let cacheTime = 0;
  let calledTimes = 0;

  const voidCache = () => {
    cacheTime = 0;
    calledTimes = 0;
  };

  const cachedFetch = async (...args: Parameters<typeof originalFetch>): ReturnType<typeof originalFetch> => {
    // if we have fetched between 0 and 30 times since the last call, and it has been less than 5 minutes
    // just return the previous value. This call is going to be lightly cached in memory, so that multiple calls
    // will not immediately re-fetch this list.
    if (Date.now() - cacheTime < TimeInMS.FIVE_MINUTES && calledTimes <= 30 && calledTimes > 0) {
      calledTimes++;
      return promise.value;
    }

    calledTimes = 1;
    cacheTime = Date.now();

    return originalFetch(...args);
  };

  const { sorted } = usePresortedLists(theRest.data, { numericKey: "number", alphaKey: "name" });
  return { fetch: cachedFetch, voidCache, promise, sorted, ...theRest };
});

export const useEquipmentLookupListForInspectionsStore = defineStore("EquipmentLookupListForInspections", () => {
  const {
    fetch: originalFetch,
    promise,
    ...theRest
  } = useLocalFetch("getEquipmentLookupListForInspection", getEquipmentLookupListForInspection);
  let cacheTime = 0;
  let calledTimes = 0;

  const voidCache = () => {
    cacheTime = 0;
    calledTimes = 0;
  };

  const cachedFetch = async (...args: Parameters<typeof originalFetch>): ReturnType<typeof originalFetch> => {
    // if we have fetched between 0 and 30 times since the last call, and it has been less than 5 minutes
    // just return the previous value. This call is going to be lightly cached in memory, so that multiple calls
    // will not immediately re-fetch this list.
    if (Date.now() - cacheTime < TimeInMS.FIVE_MINUTES && calledTimes <= 30 && calledTimes > 0) {
      calledTimes++;
      return promise.value;
    }

    calledTimes = 1;
    cacheTime = Date.now();

    return originalFetch(...args);
  };

  const { sorted } = usePresortedLists(theRest.data, { numericKey: "number", alphaKey: "name" });
  return { fetch: cachedFetch, voidCache, promise, sorted, ...theRest };
});
