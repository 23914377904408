import { type ComposerTranslation, type ComposerDateTimeFormatting } from "vue-i18n";
import { type WorkpackItemDto } from "~/connectables/fetch/transactions.ts";
import dayjs from "dayjs";

// date
export const getLocalizedWeekdayArray = (t: ComposerTranslation) => [
  t("date.sunday"),
  t("date.monday"),
  t("date.tuesday"),
  t("date.wednesday"),
  t("date.thursday"),
  t("date.friday"),
  t("date.saturday"),
];

export function isInvalidDateOrString(source: string | Date | null) {
  return (
    !source ||
    (typeof source === "string" && source?.length < 3) ||
    (source instanceof Date && isNaN((<Date>source).valueOf()))
  );
}

// set time to middle of the day to avoid time zone/daylight savings shenanigans
export function addTimeToDateString(date: string | Date) {
  if (!date || isInvalidDateOrString(date)) return null;
  let dateString;
  if (date instanceof Date) {
    dateString = date.toISOString().split("T")?.[0];
  }
  if (typeof date === "string" && date.includes("T")) {
    dateString = date?.split("T")?.[0];
  }
  if (typeof date === "string") {
    // handle 2/5/2024 format from workpack options without regex
    dateString = new Date(date).toISOString().split("T")?.[0];
  }
  dateString += "T12:30:30.0000Z";
  return dateString;
}

export const formatDateString = (source: string | Date | null, d: ComposerDateTimeFormatting) => {
  if (!source || isInvalidDateOrString(source)) {
    return "--";
  }
  const dateString = addTimeToDateString(source);
  if (!dateString) return "--";
  const date = new Date(dateString);
  return d(date, "short");
};

export const formatTimeString = (source: string | Date | null, d: ComposerDateTimeFormatting) => {
  if (!source || isInvalidDateOrString(source)) {
    return "--";
  }
  const dateString = addTimeToDateString(source);
  if (!dateString) return "--";
  const date = new Date(dateString);
  return d(date, "timeShort");
};

export const isValidDateString = (timestamp: string) => {
  return dayjs(timestamp).isValid();
};

export const getDateStringDay = (timestamp: string) => {
  if (isInvalidDateOrString(timestamp) || timestamp?.length < 3) {
    return "";
  }
  const dateString = addTimeToDateString(timestamp);
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.getDay();
};

function padNumber(num: number) {
  if (num < 10) return num.toString().padStart(2, "0");
  return num;
}

export const getDateString = (source: string | Date | null) => {
  if (!source || isInvalidDateOrString(source)) {
    return null;
  }
  const dateString = addTimeToDateString(source);
  if (!dateString) return null;
  const date = new Date(dateString);
  const month = padNumber(date.getMonth() + 1);
  const day = padNumber(date.getDate());
  return `${date.getFullYear()}-${month}-${day}`;
};

export function formatWorkpackDateLabel(timestamp: string, locale: string) {
  if (!timestamp || isInvalidDateOrString(timestamp)) return null;
  const dateTimeString = addTimeToDateString(timestamp);
  if (!dateTimeString) return null;
  const dateOptions = {
    year: "numeric" as const,
    month: "2-digit" as const,
    day: "2-digit" as const,
  };
  const date = new Date(dateTimeString);
  const dateString = new Intl.DateTimeFormat(locale, dateOptions).format(date);
  const shortWeekday = new Intl.DateTimeFormat(locale, { weekday: "short" }).format(date);

  return `${shortWeekday}, ${dateString}`;
}

export function formatWorkpackDateLabelName(timestamp: string, locale: string) {
  if (!timestamp || isInvalidDateOrString(timestamp)) return null;
  const dateTimeString = addTimeToDateString(timestamp);
  if (!dateTimeString) return null;
  const date = new Date(dateTimeString);
  const shortWeekday = new Intl.DateTimeFormat(locale, { weekday: "short" }).format(date);

  return `${shortWeekday}`;
}

export function formatWorkpackDayAndDateString(day: number, date: string, locale: string) {
  if (!day || !date || !locale) return "";
  if (day > 7 || day < 1) return "";
  return `D${day}, ${formatWorkpackDateLabel(date, locale)}`;
}

// time
export function getTotalForecastMinutes(wp: WorkpackItemDto[]) {
  return wp?.reduce((acc: number, row: WorkpackItemDto) => {
    const minutes = row?.forecastMinutes ? row.forecastMinutes : 0;
    return acc + minutes;
  }, 0);
}

export function formatTotalMinutes(totalMins: number, t: ComposerTranslation) {
  const hours = Math.floor(totalMins / 60);
  const minutes = totalMins % 60;
  const hrs = t("formFields.hrs");
  const mins = t("formFields.mins");
  return hours > 0 ? `${hours}${hrs} ${minutes}${mins}` : `${minutes}${mins}`;
}

export function getDayDifference(start: Date, end: Date) {
  if (end < start) throw Error("End Date must be after Start Date");

  return Math.round((Number(end) - Number(start)) / TimeInMS.ONE_DAY);
}

export function addDays(start: Date | string, value: number) {
  const date = new Date(start);
  date.setDate(date.getDate() + value);
  return date;
}

export const TimeInMS = {
  ONE_SECOND: 1000,
  FIVE_SECONDS: 5 * 1000,
  TEN_SECONDS: 10 * 1000,
  THIRTY_SECONDS: 30 * 1000,
  ONE_MINUTE: 60 * 1000,
  FIVE_MINUTES: 5 * 60 * 1000,
  ONE_HOUR: 1000 * 60 * 60,
  ONE_DAY: 1000 * 60 * 60 * 24,
};
