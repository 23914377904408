import { NotificationType, useGlobalNotification } from "~/store/global-notification.ts";
import { ref, computed, type Ref, type ComputedRef, type UnwrapRef, type UnwrapNestedRefs } from "vue";
import type { ApiResponse, RequestOpts, WithHeaders } from "oazapfts/lib/runtime";
import type { NitroFetchRequest } from "nitropack";
import type { FetchContext, FetchResponse } from "ofetch";
import { useCurrentPlant } from "~/store/plant-state.ts";
import { createCustomFetch, type CreateCustomFetchOpts } from "~/composables/customFetch.ts";

export const useMsalHeaders = async () => {
  const { InteractionRequiredAuthError } = await import("@azure/msal-browser");
  const { $auth, $i18n } = useNuxtApp();
  const { data } = storeToRefs(useCurrentPlant());
  const locale = $i18n.locale.value;
  const globalNotification = useGlobalNotification();

  const headers = new Headers();
  if (typeof data.value?.plantId === "number") {
    headers.append("Nexcor-Plant", `${data.value.plantId}`);
  } else {
    // We need this header to be set, otherwise things get angry.
    headers.append("Nexcor-Plant", ``);
  }

  headers.append("Accept-Language", locale);
  try {
    const bearer = "Bearer " + (await $auth.getToken());
    headers.append("Authorization", bearer);
  } catch (e) {
    if (e instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      devConsole.error("need new token!", e);
      await $auth.refreshToken();
    } else if (e instanceof Error) {
      globalNotification.showError(e.toString());
    }
  }

  return headers;
};

export const useApiOpts = async () => {
  const headers = await useMsalHeaders();
  const config = useRuntimeConfig();

  return {
    headers: headers,
    baseURL: config.public.API_URL,
    server: false,
  };
};

function createGlobalFetchHandlers<DataT>() {
  const globalNotification = useGlobalNotification();

  return {
    async onResponseError({
      response,
    }: FetchContext & {
      response: FetchResponse<DataT>;
    }) {
      if (response.status >= 500) {
        globalNotification.showError("errors.generic");
        return;
      }

      if (response.status >= 400) {
        globalNotification.showError(response._data.toString());
        return;
      }
    },
  };
}

/**
 * @deprecated Please migrate this call to use `useLocalFetch`
 */
export async function useApiFetch<DataT>(url: NitroFetchRequest | (() => NitroFetchRequest), opts = {}) {
  if (!hasInjectionContext()) {
    devConsole.log("useApiFetch Called Improperly!!");
  }
  const apiOpts = await useApiOpts();
  const handlers = createGlobalFetchHandlers<DataT>();
  devConsole.groupCollapsed(`DEPRECATION WARNING: useApiFetch("${url.toString()}", ${JSON.stringify(opts)})`);
  devConsole.log({ ...handlers, ...opts, ...apiOpts });
  devConsole.trace();
  devConsole.groupEnd();
  return useFetch<DataT>(url, {
    ...handlers,
    ...opts,
    ...apiOpts,
  });
}

export enum FETCH_STATE {
  INITIAL = "INITIAL",
  PENDING = "PENDING",
  RESOLVED = "RESOLVED",
  REJECTED = "REJECTED",
}

export interface LocalFetchResponse<T> extends WithHeaders<ApiResponse> {
  data?: T | LocalFetchError;
}

/**
 * @throws {TypeError}
 * @throws {DOMException}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GeneratedFetch<T, Args extends any[] = any[]> = (...args: Args) => Promise<LocalFetchResponse<T>>;

export class LocalFetchError {
  constructor(
    public type: "json" | "text" | "error",
    public body: null | string | Record<string, string> | Error,
  ) {}
}

export type LocalReactiveFetchHandler<T_FetchType, T_FetchMethod extends GeneratedFetch<T_FetchType>> = {
  fetchState: Ref<FETCH_STATE>;
  response: Ref<UnwrapRef<LocalFetchResponse<T_FetchType>> | LocalFetchResponse<T_FetchType>>;
  promise: Ref<Promise<LocalFetchResponse<T_FetchType>>>;
  data: ComputedRef<T_FetchType | UnwrapRef<T_FetchType | null | undefined>>;
  errors: ComputedRef<LocalFetchError | null | undefined>;
  status: ComputedRef<number>;
  pending: ComputedRef<boolean>;
  initial: ComputedRef<boolean>;
  resolved: ComputedRef<boolean>;
  rejected: ComputedRef<boolean>;
  reset: () => void;
  fetch: (...args: Parameters<T_FetchMethod>) => Promise<LocalFetchResponse<T_FetchType>>;
  download: (
    filename: string,
    blobOptions: BlobPropertyBag,
    ...fetchArgs: Parameters<T_FetchMethod>
  ) => Promise<LocalFetchResponse<T_FetchType>>;
};

class ApiRequestOpts implements RequestOpts {
  /** Override the base url for the API. */
  public baseUrl?: string;
  /** Custom Fetch handler **/
  public fetch?: typeof fetch;
  /** Custom way of creating a FormData object */
  public formDataConstructor?: new () => FormData;
  /** A Headers object, an object literal, or an array of two-item arrays to set request's headers. */
  public headers?: Record<string, string | number | boolean | undefined>;
  /** A string indicating how the request will interact with the browser's cache to set request's cache. */
  public cache?: RequestCache;
  /** A string indicating whether credentials will be sent with the request always, never, or only when sent to a same-origin URL. Sets request's credentials. */
  public credentials?: RequestCredentials;
  /** A cryptographic hash of the resource to be fetched by request. Sets request's integrity. */
  public integrity?: string;
  /** A boolean to set request's keepalive. */
  public keepalive?: boolean;
  /** A string to set request's method. */
  public method?: string;
  /** A string to indicate whether the request will use CORS, or will be restricted to same-origin URLs. Sets request's mode. */
  public mode?: RequestMode;
  /** A string indicating whether request follows redirects, results in an error upon encountering a redirect, or returns the redirect (in an opaque fashion). Sets request's redirect. */
  public redirect?: RequestRedirect;
  /** A string whose value is a same-origin URL, "about:client", or the empty string, to set request's referrer. */
  public referrer?: string;
  /** A referrer policy to set request's referrerPolicy. */
  public referrerPolicy?: ReferrerPolicy;
  /** An AbortSignal to set request's signal. */
  public signal?: AbortSignal | null;
  /** Can only be null. Used to disassociate request from any Window. */
  public window?: null;

  constructor(opts: RequestOpts) {
    Object.assign(this, opts);
  }
}

export function apiRequestOpts(opts: RequestOpts): ApiRequestOpts {
  return new ApiRequestOpts(opts);
}

/**
 *
 * @param idName - Reserved for future use
 * @param fetchMethod - Oazapfts generated fetch handler
 * @param customFetchOpts - Custom fetch options for extra handlers
 */
export function useLocalFetch<
  T_FetchType extends Extract<
    /**
     * Okay so what we are doing here is we need to extract any union type that isn't null or undefined or never.
     * There is also the possibility that this returns a function somehow? not clear on why that is. BUT we need
     * to find the types where `data` is provided on an object. if that is there, then we will have a return value.
     *
     * So we need to exclude the function, get rid of the nullable types, and then extract where `data: any` exists.
     *
     * Hence, why I am disabling linting the next couple of lines. This is needed here.
     **/
    // eslint-disable-next-line @typescript-eslint/ban-types
    Exclude<NonNullable<Awaited<ReturnType<T_FetchMethod>>>, Function>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { data: any }
  >["data"],
  T_FetchMethod extends GeneratedFetch<T_FetchType> = GeneratedFetch<T_FetchType>,
>(
  idName: string,
  fetchMethod: T_FetchMethod,
  customFetchOpts?: CreateCustomFetchOpts,
): LocalReactiveFetchHandler<T_FetchType, T_FetchMethod> {
  if (!hasInjectionContext()) {
    devConsole.error(`useLocalFetch("${idName}, ${fetchMethod.name}") Called Improperly!`);
    devConsole.error(`please only call useLocalFetch in the \`setup\` Context of a component or store!`);
    devConsole.groupCollapsed(`stackTrace`);
    devConsole.trace();
    devConsole.groupEnd();
  }
  type T_FetchResponse = LocalFetchResponse<T_FetchType>;

  type T_RefResponse = Ref<UnwrapRef<T_FetchResponse> | T_FetchResponse>;
  // Fetch methods are generated by Oazapfts. We need to check if there are optional parameters with a
  // set default. For those methods, if we don't add undefined before the ApiRequestOpts object, it will
  // set the ApiRequestOpts object properties as parameters.
  const argString = /function\s*\S+\s*\((.+)\)\s*\{/g.exec(fetchMethod.toString())![1];
  const createEmptyResponse = (): T_FetchResponse => ({ status: 0, headers: {} }) as T_FetchResponse;

  const __customFetch = createCustomFetch(customFetchOpts);
  const config = useRuntimeConfig();

  const globalNotification = useGlobalNotification();

  const fetchState: Ref<FETCH_STATE> = ref(FETCH_STATE.INITIAL);
  const response: T_RefResponse = ref<T_FetchResponse>(createEmptyResponse());
  const promise: Ref<Promise<T_FetchResponse | LocalFetchResponse<T_FetchType>>> = ref(
    Promise.resolve(createEmptyResponse()),
  );
  const errors = computed<null | LocalFetchError>(() =>
    response.value.status >= 400 || response.value.status === 0 ? (response.value.data as LocalFetchError) : null,
  );
  const data = computed<T_FetchType | null>(() =>
    response.value.status < 400 && response.value.status > 0 ? (response.value.data as T_FetchType) : null,
  );

  const status = computed<number>(() => response.value.status || 0);
  const pending = computed(() => {
    return fetchState.value === FETCH_STATE.PENDING;
  });
  const resolved = computed(() => {
    return fetchState.value === FETCH_STATE.RESOLVED;
  });
  const rejected = computed(() => {
    return fetchState.value === FETCH_STATE.REJECTED;
  });
  const initial = computed(() => {
    return fetchState.value === FETCH_STATE.INITIAL;
  });
  const reset = () => {
    response.value = createEmptyResponse();
    promise.value = Promise.resolve(createEmptyResponse());
    fetchState.value = FETCH_STATE.INITIAL;
  };

  const fetch = async (...args: Parameters<typeof fetchMethod>): Promise<T_FetchResponse> => {
    fetchState.value = FETCH_STATE.PENDING;
    if (argString?.split(",").length > 1 && args.length === 0) {
      args.push(undefined);
    }
    const argLength = args?.length;
    let opts = args.pop();

    if (opts instanceof ApiRequestOpts) {
      opts.fetch = __customFetch;
      opts.baseUrl = config.public.API_OAZAPFTS_URL;
    } else {
      // if there are no args, we don't need to put our popped undefined arg back in the array
      if (argLength > 0) args.push(opts);
      opts = apiRequestOpts({
        baseUrl: config.public.API_OAZAPFTS_URL,
        fetch: __customFetch,
      });
    }

    args.push(opts);
    try {
      promise.value = fetchMethod(...args);
      response.value = await promise.value;
      if (response.value.status === 0) {
        response.value.data = new LocalFetchError("error", "CORS Error");
      }
      if (response.value.status >= 400) {
        fetchState.value = FETCH_STATE.REJECTED;
        try {
          const r = __customFetch.meta.response.clone();
          response.value.data = new LocalFetchError("json", await r.json());
        } catch (e) {
          if (e instanceof TypeError) {
            const r = __customFetch.meta.response.clone();
            response.value.data = new LocalFetchError("text", await r.text());
          }
        }
      } else {
        fetchState.value = FETCH_STATE.RESOLVED;
      }
    } catch (e) {
      if (import.meta.env.DEV) {
        devConsole.log("Rejected!");
        devConsole.dir(e);
      }
      fetchState.value = FETCH_STATE.REJECTED;
      response.value = {
        status: 600,
        headers: new Headers(),
        data: new LocalFetchError("error", e as Error),
      };
    }

    if (import.meta.env.VITEST) {
      if (response.value.status >= 500 && response.value.data instanceof LocalFetchError) {
        throw new Error(
          `Api returned ${response.value.status} code with message: "${JSON.stringify(response.value.data.body, null, 2)}"`,
        );
      }
    }
    return promise.value;
  };

  const download = async (
    filename: string = "download",
    blobOptions: BlobPropertyBag = { type: "application/octet-stream" },
    ...fetchArgs: Parameters<typeof fetchMethod>
  ) => {
    const messageHandle = globalNotification.showPending(`Downloading ${filename}`);
    await fetch(...fetchArgs);
    if (resolved.value) {
      const res = __customFetch.meta.response.clone();
      const file = await res.blob();
      await messageHandle.setFile(file, filename);
      messageHandle.setTimeout(TimeInMS.FIVE_SECONDS);
      messageHandle.setType(NotificationType.Success);
      messageHandle.setMessage(`${filename} Ready!`);
      downloadBlob(file, filename, blobOptions);
    } else {
      messageHandle.setTimeout(TimeInMS.TEN_SECONDS);
      messageHandle.setType(NotificationType.Error);
      messageHandle.setMessage(globalNotification.getError(errors.value!));
    }
    return response.value;
  };

  return {
    fetchState,
    response,
    promise,
    data,
    errors,
    status,
    pending,
    initial,
    resolved,
    rejected,
    reset,
    fetch,
    download,
  };
}

export type ReactiveFetchHandler<T> = UnwrapNestedRefs<LocalReactiveFetchHandler<T, GeneratedFetch<T>>>;
export type FetchHandler<T> = LocalReactiveFetchHandler<T, GeneratedFetch<T>>;
